import React from "react"
import NuvoImage from "../components/NuvoImage"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/CTA/GetStarted"
import classnames from "classnames"

import MarkdownViewer from "@components/MarkdownViewer"

const CommunityQueryPage = (props) => {
  var pageContext = props.pageContext
  var location = props.location
  var data = props.data
  var post
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allUniquePagesJson.nodes[0]

        break
      case "es":
        post = data.allSpanishUniquePagesJson.nodes[0]

        break
      default:
        post = data.allUniquePagesJson.nodes[0]
    }
  } else {
    post = data.allUniquePagesJson.nodes[0]
  }

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schemaPage ? post.schemaPage : null}
        pathname={location.pathname}
      />
      <div className="joshua-tree-content community-query-page">
        {/* Top Section */}
        <div className="columns top-section white-back">
          <div className="column">
            <h1 style={{ textAlign: "center" }}>{post.heading}</h1>
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <MarkdownViewer markdown={post.blurb} />
              </div>
              <div className="column is-4"></div>
            </div>
          </div>
        </div>

        <section className="body-section community">
          {/* <Grid pages={post.pages} /> */}
          <ThreeImageGrid pages={post.pages} gridSize={4} />
        </section>

        <section
          className="body-section community-get-started color-back"
          style={{ paddingBottom: "60px" }}
        >
          <GetStarted
            sideColumnIs={3}
            headingSideColumnIs={2}
            centerHeading
            centerText
            heading={post.getStarted.heading}
            paragraph={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
        </section>
      </div>
    </Layout>
  )
}

function Grid(props) {
  // Split into rows of 2
  var chunk_size = 2
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRow key={`community-row-${i}`} items={row} />
  ))
}

function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        let page = item.page
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-2"></div>}
            <div
              className={`column community-item ${
                itemLen === i + 1 ? "is-9" : "is-9"
              }`}
            >
              <Link className="hover-link" to={page.href}>
                <NuvoImage
                  useAR
                  arWidth="100"
                  arHeight="100"
                  cloudName="nuvolum"
                  publicId={page.img}
                  width="auto"
                  responsive
                  className="meet-our-doctor"
                ></NuvoImage>
              </Link>
              <div className="community-subtext">
                <Link className="heading-link hover-link" to={page.href}>
                  <h3>{page.heading}</h3>
                </Link>
                <Link
                  className="standard-button contained"
                  style={{ marginTop: 0 }}
                  to={page.href}
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="column is-2"></div>
          </>
        )
      })}
    </div>
  )
}

function ThreeImageGrid({ pages, gridSize }) {
  const gridClass = classnames({
    "community-three-grid": !gridSize || gridSize === 3,
    "community-4-grid": gridSize === 4,
  })
  return (
    <div className="columns is-desktop community-row">
      <div className="column is-2"></div>
      <div className={`column community-item ${gridClass}`}>
        {pages &&
          pages.map((page, i) => (
            <div key={i}>
              <Link
                className="hover-link"
                to={page.page.href}
                style={{ display: "block" }}
              >
                <NuvoImage
                  useAR
                  arWidth="100"
                  arHeight="89"
                  cloudName="nuvolum"
                  publicId={page.page.img}
                  width="auto"
                  responsive
                  className="meet-our-doctor"
                ></NuvoImage>
              </Link>
              <div className="community-subtext">
                <Link className="heading-link hover-link" to={page.page.href}>
                  <h3>{page.page.heading}</h3>
                </Link>
                <Link
                  className="standard-button contained"
                  style={{ marginTop: 10 }}
                  to={page.page.href}
                >
                  Learn More
                </Link>
              </div>
            </div>
          ))}
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export const pageQuery = graphql`
  query CommunityQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        blurb
        getStarted {
          heading
          blurb
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
        }
        pages {
          page {
            heading
            img
            href
          }
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
      }
    }
  }
`

export default CommunityQueryPage
